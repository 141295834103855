import { Suspense } from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';

// helpers
import { APICore } from '../helpers/api/apiCore';

// hooks
import { useUser } from '../hooks/auth';

type PrivateRouteProps = {
    status?: boolean;
};

const loading = () => (
    <div className="div-loading">
        <div className="loading-container">
            <div className="item"></div>
            <div className="item"></div>
            <div className="item"></div>
            <div className="item"></div>
        </div>
    </div>
);

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ status, ...rest }: PrivateRouteProps) => {
    let location = useLocation();
    const { loggedInUser } = useUser();

    const api = new APICore();

    /**
     * not logged in so redirect to login page with the return url
     */
    if (api.isUserAuthenticated() === false || !localStorage.getItem('shield_user')) {
        return <Navigate to={'/auth/login'} state={{ from: location }} replace />;
    }

    // check if route is restricted by role
    if (status && loggedInUser.status === false && !localStorage.getItem('shield_user')) {
        // role not authorised so redirect to home page
        return <Navigate to={{ pathname: '/auth/login' }} />;
    }
    return (
        <Suspense fallback={loading()}>
            <Outlet />
        </Suspense>
    );
};

export default PrivateRoute;
