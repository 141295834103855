import { Suspense } from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';

// helpers
// import { APICore } from '../helpers/api/apiCore';

// hooks
// import { useUser } from '../hooks/auth';

const loading = () => (
    <div className="div-loading">
        <div className="loading-container">
            <div className="item"></div>
            <div className="item"></div>
            <div className="item"></div>
            <div className="item"></div>
        </div>
    </div>
);

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const GlobalRoutes = ({ ...rest }) => {
    let location = useLocation();
    // const {loggedInUser} = useUser();

    // const api = new APICore();

    /**
     * not logged in so redirect to login page with the return url
     */
    if (localStorage.getItem('shield_user')) {
        return <Navigate to={'/dashboard'} state={{ from: location }} replace />;
    }

    return (
        <Suspense fallback={loading()}>
            <Outlet />
        </Suspense>
    );
};

export default GlobalRoutes;
