import { Navigate } from 'react-router-dom';
import { useUser } from '../hooks/auth';

const Root = () => {
    const { loggedInUser: user } = useUser();

    const getRootUrl = () => {
        let url: string = 'dashboard';

        return url;
    };

    const url = getRootUrl();

    return <>{!user ? <Navigate to={`/auth/login`} /> : <Navigate to={`/${url}`} />}</>;
};

export default Root;
